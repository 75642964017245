import { Subscription, SubscriptionResponse } from "../lambda/subscribe";

export const subscribe = async (subscription: Subscription): Promise<SubscriptionResponse> => {
  return call('subscribe', subscription)
}

const call = <TInput, TOutput>(lambda: string, request: TInput): Promise<TOutput> => {
  return new Promise<TOutput>(async (resolve, reject) => {
    try {
      const response = await fetch("/.netlify/functions/" + lambda, {
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/plain"
        },
        body: JSON.stringify(request)
      })
      if (response.status === 200) {
        resolve(await response.json())
      } else {
        reject(response.statusText)
      }
    } catch(e) {
      reject(e)
    }
  })
}