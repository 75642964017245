import * as R from 'ramda'
import React, { useReducer } from 'react'

import styled from "styled-components"
import { Column, Row, Fader } from "./utils"
import Spinner from './spinner'
import { subscribe } from '../services/newsletter'
import { CinemaId, Movie } from '../model/model'
import { Subscription, SubscriptionResponse } from '../lambda/subscribe';
import { ActionResult } from '../model/actions';

const Newsletter = styled(Column)`
display: flex;
padding: 5px;
align-items: center;

width: calc(100% + 10px);
margin: 10px -5px;
@media(min-width: 700px) {
  width: 600px;
  margin: 0 auto;
}

& > ${Row} {
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
`
const NewsletterTitle = styled.h4`
text-align: center;
font-weight: 100;
color: ${props => props.theme.colors.highlight};
text-transform: uppercase;
line-height: 1.3rem;
margin-bottom: 0;
`

const NewsletterInput = styled.input`
border: none;
background: #fff;
padding: 5px;
margin: 5px 0px;
text-align: center;
width: 100%;
@media(min-width: 700px) {
  width: 300px;
  text-align: left;
}
`

const NewsletterButton = styled.input`
color: ${props => props.theme.colors.highlight};
border: none;
text-align: center;
background: #333;
cursor: pointer;
width: fit-content;
padding: 5px 50px;
margin: 0;
@media(min-width: 700px) {
  margin: 5px 0px;
}
&[disabled] {
  cursor: not-allowed;

}
`

const Disclaimer = styled(Fader)`
margin: 0;
text-align: center;
font-size: 14px;
line-height: 17px;
color: ${props => props.theme.colors.attenuate};
`

const SuccessMessage = styled.p`
text-align: center;
color: #87BB08;
`

const ErrorMessage = styled.p`
text-align: center;
color: red;
`

interface FormProps {
  text?: string | null
  buttonText?: string
  formName: string
  cinemaId: CinemaId
  movie: Movie | null
  tags: string[]
  onSuccess?: (email: string, formName: string, message: string) => void
  onError?: (message: string) => void
}

interface State {
  email: string
  state: 'ready' | 'submitting' | 'success' | 'error'
  successMessage: string
  errorMessage: string
}

const reducer = (state: State, action: Partial<State>): State => {
  return {
    ...state,
    ...action
  }
}

const Actions = (present: (change: Partial<State>) => void) => ({
  subscribeEmail: async (subscription: Subscription): Promise<ActionResult<SubscriptionResponse, string>> => {
    present({ state: 'submitting' })
    try {
      const result = await subscribe(subscription)
      if (result.status === 'success') {
        present({state: result.status, successMessage: result.message})
        return {
          type: 'success',
          payload: result
        }
      } else {
        present({state: 'error', errorMessage: result.message})
        return {
          type: 'error',
          error: result.message
        }
      }
    } catch(e) {
      present({state: 'error'})
      return {
        type: 'error',
        error: `C'è stato un problema durante la registrazione, riprova più tardi`
      }
    }
  },
  updateEmail: (email: string) => {
    present({email: email})
  }
})

export const NewsletterForm: React.SFC<FormProps> = ({text, buttonText, formName, tags, cinemaId, movie, onSuccess, onError}) => {
  const initial: State = {
    email: '',
    state: 'ready' as 'ready' | 'submitting' | 'success' | 'error',
    successMessage: 'Sei stato registrato con successo!',
    errorMessage: `C'è stato un problema durante la registrazione, riprova più tardi`
  }
  const [state, present] = useReducer(reducer, initial);
  const actions = Actions(present);

  switch(state.state) {
  case 'ready':
    const t = text === undefined ? 'Resta aggiornato su eventi e nuove uscite' : text
    const bt = buttonText || 'Registrami!'
    return (
      <Newsletter as="form" onSubmit={async (e) => {
        e.preventDefault()
        e.stopPropagation()
        const res = await actions.subscribeEmail({
          email: state.email,
          cinemaId: cinemaId,
          source: `website/${cinemaId}/${formName}`,
          tags: R.concat(movie && movie.category || [], tags),
          movieId: movie && movie.slug || '',
          movieTitle: movie && movie.title || ''
        })
        res.type === 'success'
          ? onSuccess && onSuccess(state.email, formName, res.payload.message)
          : onError && onError(res.error)
      }}>
        { t != null && <NewsletterTitle>{t}</NewsletterTitle> }
        <Row>
          <NewsletterInput type="email" placeholder="email" value={state.email}
            onChange={e => actions.updateEmail(e.target.value)}/>
          <NewsletterButton disabled={state.email.length === 0} type="submit" value={bt}></NewsletterButton>
          {state.email.length > 0
          && <Disclaimer as="p">
                Cliccando "{bt}" accetti di ricevere la nostra newsletter settimanale.
                Potrai disiscriverti in ogni momento grazie al link presente in ogni email.
              </Disclaimer>
          }
        </Row>
      </Newsletter>
    )

  case 'submitting':
    return <Spinner/>

  case 'success':
    return <SuccessMessage>{state.successMessage}</SuccessMessage>

  case 'error':
    return <ErrorMessage>{state.errorMessage}</ErrorMessage>

  default:
    const x: never = state.state
    return null
  }
}